
body{
  background-color: #000000 !important;
  color: #fff !important;
}

.st-btn-style {
  background: linear-gradient(to top, #3a22ff 0%, #5450ed 100%);
  text-shadow: 1px 1px 0 #0000004b;
  transition: all 0.5s ease-in-out;
}

.st-btn-style > b{
  font-size: 18px;
}

.st-btn {
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
 }


.st-btn:hover {
  background-position: right center; 
  text-shadow: 2px 2px  #0000004b;
  transition: all 0.5s ease-in-out;
}

.MuiFormLabel-root {
  color: #fff !important;
}
.MuiInputBase-input{
  color: #fff !important;
}

input {
  font-size: 16px !important;
}